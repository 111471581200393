import { AddIcon, ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Polygon } from "components";
import { MainLayout } from "layouts";
import Papa from "papaparse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Circle, Layer, Line, Stage } from "react-konva";
import * as XLSX from "xlsx";

const DESIRED_IMAGE_HEIGHT = window.innerHeight * 0.7;
const XLSX_FILE = {
  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  extension: ".xlsx",
};
const JSON_FILE = {
  type: "application/json",
  extension: ".json",
};
const CSV_FILE = {
  type: "text/csv",
  extension: ".csv",
};
const initState = {
  id: "",
  cameraId: "",
  mondayStart: "09:00",
  mondayEnd: "17:00",
  tuesdayStart: "09:00",
  tuesdayEnd: "17:00",
  wednesdayStart: "09:00",
  wednesdayEnd: "17:00",
  thursdayStart: "09:00",
  thursdayEnd: "17:00",
  fridayStart: "09:00",
  fridayEnd: "17:00",
  saturdayStart: "09:00",
  saturdayEnd: "17:00",
  sundayStart: "09:00",
  sundayEnd: "17:00",
};

export default function Home() {
  const [{ vectorHeight, vectorWidth }, setVectorDimensions] = useState({
    vectorHeight: 500,
    vectorWidth: 500,
  });
  const [{ naturalHeight, naturalWidth }, setNaturalDimensions] = useState({
    naturalHeight: 500,
    naturalWidth: 500,
  });
  const [state, setState] = useState(initState);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [selected, setSelected] = useState();
  const [spots, setSpots] = useState([]);
  const [isDrawing, setDrawing] = useState(false);
  const [mousePosition, setMousePosition] = useState();
  const [points, setPoints] = useState([]);
  const toast = useToast();
  const loadFileRef = useRef();
  const stageRef = useRef(null);

  /* -------------------------- Box Related Functions ------------------------- */
  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelected(undefined);
    }
  };
  /* ---------------------- Box Product Related Functions --------------------- */
  const onChangeSpot = (item, key) => (e) => {
    setSpots((prevState) =>
      prevState.map((item2) =>
        item2.id === item.id
          ? {
              ...item2,
              [key]: e.target.value,
            }
          : item2
      )
    );
  };
  const onDeleteSpot = (item) => () => {
    setSpots((prevState) => prevState.filter((item2) => item2.id !== item.id));
  };
  const onAddSpot = () => {
    setDrawing(true);
  };
  /* ------------------------------ Drawing Stage ----------------------------- */
  const onMouseEnterStage = useCallback(() => {
    const stage = stageRef.current;
    const point = stage.getPointerPosition();

    if (point && isDrawing) {
      setMousePosition(point);
    }
  }, [isDrawing]);
  const onMouseLeaveStage = useCallback(() => {
    setMousePosition(undefined);
  }, []);
  const onClickStage = useCallback(() => {
    // console.log("onClickStage")
    const stage = stageRef.current;
    const point = stage.getPointerPosition();
    if (point && isDrawing) {
      if (points.length === 3) {
        const newZone = {
          id: Math.random() + "",
          number: "",
          time: "",
          points: [...points, point],
          rotation: 0,
          isDragging: false,
        };
        console.log(newZone);
        setPoints([]);
        setSpots((prevState) => [...prevState, newZone]);
        setSelected(newZone);
        setDrawing(false);
      } else {
        setPoints([...points, point]);
      }
    }
  }, [points, isDrawing]);
  /* ------------------------- Image Related Functions ------------------------ */
  const onLoad = (e) => {
    var maxWidth = 500;
    // console.log("maxWidth", maxWidth);
    const parentElement = document.getElementById("parent-image-container");
    if (parentElement) {
      maxWidth = parentElement.clientWidth;
    }
    const { naturalWidth, naturalHeight } = e.currentTarget;
    let scale = DESIRED_IMAGE_HEIGHT / naturalHeight;
    if (naturalWidth * scale > maxWidth) {
      scale = maxWidth / naturalWidth;
    }
    setVectorDimensions({
      vectorWidth: naturalWidth * scale,
      vectorHeight: naturalHeight * scale,
    });
    setNaturalDimensions({
      naturalWidth,
      naturalHeight,
    });
  };
  const onClose = () => {
    setImage(undefined);
    setImageUrl(undefined);
    setSpots([]);
    setSelected(undefined);
  };
  const onSelect = (acceptedFile) => {
    setImage(acceptedFile);
    setImageUrl(URL.createObjectURL(acceptedFile));
    setSpots([]);
    setState(initState);
    setSelected(undefined);
  };
  const dropZone = useDropzone({
    onDrop: (acceptedFiles) => {
      let acceptedFile = acceptedFiles[0];
      if (acceptedFile) {
        onSelect(acceptedFile);
      }
    },
    accept: { "image/png": [".png", ".jpg", ".jpeg"] },
    multiple: false,
  });
  const normalizeX = (x) => Math.round(x * (naturalWidth / vectorWidth));
  const normalizeY = (y) => Math.round(y * (naturalHeight / vectorHeight));
  const denormalizeX = (x) => x * (vectorWidth / naturalWidth);
  const denormalizeY = (y) => y * (vectorHeight / naturalHeight);

  /* ------------------------- Files related functions ------------------------ */
  const toAccept = (array) =>
    array?.map((item) => Object.values(item).join(", ")).join(", ");

  const onDownloadJSON = () => {
    const finalJSON = {
      parking_lot_id: state.id,
      camera_id: state.cameraId,
      monday_start: state.mondayStart,
      monday_end: state.mondayEnd,
      tuesday_start: state.tuesdayStart,
      tuesday_end: state.tuesdayEnd,
      wednesday_start: state.wednesdayStart,
      wednesday_end: state.wednesdayEnd,
      thursday_start: state.thursdayStart,
      thursday_end: state.thursdayEnd,
      friday_start: state.fridayStart,
      friday_end: state.fridayEnd,
      saturday_start: state.saturdayStart,
      saturday_end: state.saturdayEnd,
      sunday_start: state.sundayStart,
      sunday_end: state.sundayEnd,
      image_resolution: [naturalWidth, naturalHeight],
      zone_definitions: spots?.map((item) => ({
        number: item.number,
        time: item.time,
        zone_coordinates: item.points?.map(({ x, y }) => [
          normalizeX(x),
          normalizeY(y),
        ]),
      })),
    };
    const jsonString = JSON.stringify(finalJSON);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = image.name + "-" + Date.now() + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const onDownloadCSVCoordinates = () => {
    const csvJSON = spots?.map(({ number, time, points }) => {
      let result = { number, time };
      points?.forEach(({ x, y }, i) => {
        result[`X${i + 1}`] = normalizeX(x);
        result[`Y${i + 1}`] = normalizeY(y);
      });
      return result;
    });
    const csvString = Papa.unparse(csvJSON, { delimiter: "," });
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Coordinates - " + image.name + "-" + Date.now() + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const onDownloadCSVDetails = () => {
    const csvJSON = [
      {
        parking_lot_id: state.id,
        camera_id: state.cameraId,
        monday_start: state.mondayStart,
        monday_end: state.mondayEnd,
        tuesday_start: state.tuesdayStart,
        tuesday_end: state.tuesdayEnd,
        wednesday_start: state.wednesdayStart,
        wednesday_end: state.wednesdayEnd,
        thursday_start: state.thursdayStart,
        thursday_end: state.thursdayEnd,
        friday_start: state.fridayStart,
        friday_end: state.fridayEnd,
        saturday_start: state.saturdayStart,
        saturday_end: state.saturdayEnd,
        sunday_start: state.sundayStart,
        sunday_end: state.sundayEnd,
        image_width: naturalWidth,
        image_height: naturalHeight,
      },
    ];
    const csvString = Papa.unparse(csvJSON, { delimiter: "," });
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Details - " + image.name + "-" + Date.now() + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const onDownloadCSV = () => {
    onDownloadCSVDetails();
    onDownloadCSVCoordinates();
  };
  const onDownloadXLSX = () => {
    const coordinatesJSON = spots?.map(({ number, time, points }) => {
      let result = { number, time };
      points?.forEach(({ x, y }, i) => {
        result[`X${i + 1}`] = normalizeX(x);
        result[`Y${i + 1}`] = normalizeY(y);
      });
      return result;
    });
    const csvJSON = [
      {
        parking_lot_id: state.id,
        camera_id: state.cameraId,
        monday_start: state.mondayStart,
        monday_end: state.mondayEnd,
        tuesday_start: state.tuesdayStart,
        tuesday_end: state.tuesdayEnd,
        wednesday_start: state.wednesdayStart,
        wednesday_end: state.wednesdayEnd,
        thursday_start: state.thursdayStart,
        thursday_end: state.thursdayEnd,
        friday_start: state.fridayStart,
        friday_end: state.fridayEnd,
        saturday_start: state.saturdayStart,
        saturday_end: state.saturdayEnd,
        sunday_start: state.sundayStart,
        sunday_end: state.sundayEnd,
        image_width: naturalWidth,
        image_height: naturalHeight,
      },
    ];
    // console.log("coordinatesJSON", JSON.stringify(coordinatesJSON));
    const details = [
      Object.keys(csvJSON[0]),
      ...csvJSON?.map((obj) => Object.values(obj)),
    ];
    const coordinates = [
      Object.keys(coordinatesJSON[0]),
      ...coordinatesJSON?.map((obj) => Object.values(obj)),
    ];
    const detailsWorksheet = XLSX.utils.aoa_to_sheet(details);
    const coordinatesWorksheet = XLSX.utils.aoa_to_sheet(coordinates);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, detailsWorksheet, "Details");
    XLSX.utils.book_append_sheet(workbook, coordinatesWorksheet, "Coordinates");
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = image.name + "-" + Date.now() + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const onDownload = () => {
    onDownloadJSON();
    onDownloadCSV();
    onDownloadXLSX();
  };
  const loadJSON = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const json = JSON.parse(contents);
        // Do something with the JSON object
        // console.log(json);
        const {
          parking_lot_id,
          camera_id,
          monday_start,
          monday_end,
          tuesday_start,
          tuesday_end,
          wednesday_start,
          wednesday_end,
          thursday_start,
          thursday_end,
          friday_start,
          friday_end,
          saturday_start,
          saturday_end,
          sunday_start,
          sunday_end,
          zone_definitions,
        } = json;

        if (parking_lot_id) {
          setState((prevState) => ({
            ...prevState,
            id: parking_lot_id,
          }));
        }
        if (camera_id) {
          setState((prevState) => ({
            ...prevState,
            cameraId: camera_id,
          }));
        }
        if (monday_start) {
          setState((prevState) => ({
            ...prevState,
            mondayStart: monday_start,
          }));
        }
        if (monday_end) {
          setState((prevState) => ({
            ...prevState,
            mondayEnd: monday_end,
          }));
        }
        if (tuesday_start) {
          setState((prevState) => ({
            ...prevState,
            tuesdayStart: tuesday_start,
          }));
        }
        if (tuesday_end) {
          setState((prevState) => ({
            ...prevState,
            tuesdayEnd: tuesday_end,
          }));
        }
        if (wednesday_start) {
          setState((prevState) => ({
            ...prevState,
            wednesdayStart: wednesday_start,
          }));
        }
        if (wednesday_end) {
          setState((prevState) => ({
            ...prevState,
            wednesdayEnd: wednesday_end,
          }));
        }
        if (thursday_start) {
          setState((prevState) => ({
            ...prevState,
            thursdayStart: thursday_start,
          }));
        }
        if (thursday_end) {
          setState((prevState) => ({
            ...prevState,
            thursdayEnd: thursday_end,
          }));
        }
        if (friday_start) {
          setState((prevState) => ({
            ...prevState,
            fridayStart: friday_start,
          }));
        }
        if (friday_end) {
          setState((prevState) => ({
            ...prevState,
            fridayEnd: friday_end,
          }));
        }
        if (saturday_start) {
          setState((prevState) => ({
            ...prevState,
            saturdayStart: saturday_start,
          }));
        }
        if (saturday_end) {
          setState((prevState) => ({
            ...prevState,
            saturdayEnd: saturday_end,
          }));
        }
        if (sunday_start) {
          setState((prevState) => ({
            ...prevState,
            sundayStart: sunday_start,
          }));
        }
        if (sunday_end) {
          setState((prevState) => ({
            ...prevState,
            sundayEnd: sunday_end,
          }));
        }
        if (zone_definitions) {
          if (!Array.isArray(zone_definitions))
            throw new Error("data is not in array format.");
          if (zone_definitions.length <= 0) throw new Error("not enough data.");
          let data = [];
          zone_definitions.forEach((item) => {
            try {
              data.push({
                id: Math.random() + "",
                number: item.number,
                time: item.time,
                points: [
                  {
                    x: denormalizeX(item.zone_coordinates[0][0]),
                    y: denormalizeY(item.zone_coordinates[0][1]),
                  },
                  {
                    x: denormalizeX(item.zone_coordinates[1][0]),
                    y: denormalizeY(item.zone_coordinates[1][1]),
                  },
                  {
                    x: denormalizeX(item.zone_coordinates[2][0]),
                    y: denormalizeY(item.zone_coordinates[2][1]),
                  },
                  {
                    x: denormalizeX(item.zone_coordinates[3][0]),
                    y: denormalizeY(item.zone_coordinates[3][1]),
                  },
                ],
                rotation: 0,
                isDragging: false,
              });
            } catch (err) {
              console.error(err);
            }
          });
          setSpots(data);
        } else {
          toast({ description: "Invalid JSON file", status: "error" });
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast({
        description: err.message || "data in file is invalid",
        status: "error",
      });
    }
  };
  const loadCSV = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const contents = e.target.result;
        const csv = Papa.parse(contents);
        // console.debug("csv", csv);
        const headers = csv?.data[0];
        // console.debug("headers", headers);
        const columns = csv?.data?.slice(1);
        if (
          !headers ||
          !Array.isArray(headers) ||
          headers.length <= 0 ||
          !columns ||
          !Array.isArray(columns) ||
          columns.length <= 0
        )
          throw new Error("invalid rows and columns.");
        // console.debug("columns", columns);
        const getIndex = (key) => headers.findIndex((header) => header === key);
        if (headers.includes("parking_lot_id")) {
          const cell = columns[0];
          if (!cell) throw new Error("No data found.");
          setState((prevState) => ({
            ...prevState,
            id: cell[getIndex("parking_lot_id")],
            cameraId: cell[getIndex("camera_id")],
            mondayStart: cell[getIndex("monday_start")],
            mondayEnd: cell[getIndex("monday_end")],
            tuesdayStart: cell[getIndex("tuesday_start")],
            tuesdayEnd: cell[getIndex("tuesday_end")],
            wednesdayStart: cell[getIndex("wednesday_start")],
            wednesdayEnd: cell[getIndex("wednesday_end")],
            thursdayStart: cell[getIndex("thursday_start")],
            thursdayEnd: cell[getIndex("thursday_end")],
            fridayStart: cell[getIndex("friday_start")],
            fridayEnd: cell[getIndex("friday_end")],
            saturdayStart: cell[getIndex("saturday_start")],
            saturdayEnd: cell[getIndex("saturday_end")],
            sundayStart: cell[getIndex("sunday_start")],
            sundayEnd: cell[getIndex("sunday_end")],
          }));
        } else {
          columns?.forEach((cell) => {
            let data = [];
            try {
              data.push({
                number: cell[getIndex("number")],
                time: cell[getIndex("time")],
                points: [
                  {
                    x: denormalizeX(cell[getIndex("X1")]),
                    y: denormalizeY(cell[getIndex("Y1")]),
                  },
                  {
                    x: denormalizeX(cell[getIndex("X2")]),
                    y: denormalizeY(cell[getIndex("Y2")]),
                  },
                  {
                    x: denormalizeX(cell[getIndex("X3")]),
                    y: denormalizeY(cell[getIndex("Y3")]),
                  },
                  {
                    x: denormalizeX(cell[getIndex("X4")]),
                    y: denormalizeY(cell[getIndex("Y4")]),
                  },
                ],
              });
            } catch (err) {
              console.error(err);
            }
            setSpots(data);
          });
        }
      } catch (err) {
        console.error(err);
        toast({
          description: err.message || "Invalid CSV file",
          status: "error",
        });
      }
    };
    reader.readAsText(file);
  };
  const loadXLSX = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const contents = new Uint8Array(e.target.result);
        const workbook = XLSX.read(contents, { type: "array" });
        const { SheetNames, Sheets } = workbook;
        if (!SheetNames || SheetNames.length <= 0) {
          throw new Error("no sheets found in selected excel file");
        }

        SheetNames?.forEach((sheetName) => {
          const worksheet = Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          if (data[0].parking_lot_id) {
            setState((prevState) => ({
              ...prevState,
              id: data[0].parking_lot_id,
              cameraId: data[0].camera_id,
              mondayStart: data[0].monday_start,
              mondayEnd: data[0].monday_end,
              tuesdayStart: data[0].tuesday_start,
              tuesdayEnd: data[0].tuesday_end,
              wednesdayStart: data[0].wednesday_start,
              wednesdayEnd: data[0].wednesday_end,
              thursdayStart: data[0].thursday_start,
              thursdayEnd: data[0].thursday_end,
              fridayStart: data[0].friday_start,
              fridayEnd: data[0].friday_end,
              saturdayStart: data[0].saturday_start,
              saturdayEnd: data[0].saturday_end,
              sundayStart: data[0].sunday_start,
              sundayEnd: data[0].sunday_end,
            }));
          } else {
            let boxes = [];
            data?.forEach((json) => {
              // console.log("json", json);
              try {
                boxes.push({
                  number: json.number,
                  time: json.time,
                  points: [
                    {
                      x: denormalizeX(json.X1),
                      y: denormalizeY(json.Y1),
                    },
                    {
                      x: denormalizeX(json.X2),
                      y: denormalizeY(json.Y2),
                    },
                    {
                      x: denormalizeX(json.X3),
                      y: denormalizeY(json.Y3),
                    },
                    {
                      x: denormalizeX(json.X4),
                      y: denormalizeY(json.Y4),
                    },
                  ],
                });
              } catch (err) {
                console.error(err);
              }
            });
            setSpots(boxes);
          }
        });
      } catch (err) {
        console.error(err);
        toast({ description: "Invalid CSV file", status: "error" });
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const onLoadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // console.log(file);
    if (file) {
      if (file.type === JSON_FILE.type) {
        loadJSON(file);
      } else if (file.type === XLSX_FILE.type) {
        loadXLSX(file);
      } else if (file.type === CSV_FILE.type) {
        loadCSV(file);
      } else {
        toast({
          description: "Invalid file type. must be xlsx, json or csv",
          status: "error",
        });
      }
    }
  };

  return (
    <MainLayout>
      <input
        ref={loadFileRef}
        value={""}
        type="file"
        accept={toAccept([XLSX_FILE, JSON_FILE, CSV_FILE])}
        readOnly
        hidden
        multiple={false}
        onChange={onLoadFile}
      />
      <Flex direction="column">
        {imageUrl ? (
          <Flex direction="column">
            <Flex marginBottom={2} justifyContent="space-between">
              <Flex>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  onClick={() => loadFileRef.current.click()}
                  marginLeft={2}
                >
                  Import
                </Button>
              </Flex>
              <Flex>
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={dropZone?.getRootProps().onClick}
                  marginRight={2}
                >
                  Change Image
                </Button>
                <Button colorScheme="red" onClick={onClose} marginRight={2}>
                  Close
                </Button>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Download
                  </MenuButton>
                  <MenuList zIndex={1000}>
                    <MenuItem onClick={onDownload}>Download</MenuItem>
                    <MenuItem onClick={onDownloadJSON}>Download JSON</MenuItem>
                    <MenuItem onClick={onDownloadCSV}>Download CSV</MenuItem>
                    <MenuItem onClick={onDownloadXLSX}>Download Excel</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>

            <Flex
              marginBottom={2}
              alignItems="center"
              justifyContent="center"
              id="parent-image-container"
              width="100%"
            >
              <Box position="relative">
                <Image
                  src={imageUrl}
                  width={vectorWidth}
                  height={vectorHeight}
                  objectFit={"contain"}
                  onLoad={onLoad}
                />
                <Stage
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 999,
                  }}
                  ref={stageRef}
                  width={vectorWidth}
                  height={vectorHeight}
                  onMouseDown={checkDeselect}
                  onTouchStart={checkDeselect}
                  onClick={onClickStage}
                  onTap={onClickStage}
                  onMouseEnter={onMouseEnterStage}
                  onMouseMove={onMouseEnterStage}
                  onMouseLeave={onMouseLeaveStage}
                >
                  <Layer>
                    {spots?.map((shape, i) => (
                      <Polygon
                        key={i}
                        shape={shape}
                        fill={shape.id === selected?.id ? "pink" : "lightblue"}
                        opacity={0.3}
                        stroke={shape.id === selected?.id ? "red" : "blue"}
                        strokeWidth={2}
                        isSelected={shape.id === selected?.id}
                        vectorDimensions={{ vectorHeight, vectorWidth }}
                        onSelect={() => {
                          setSelected(shape);
                        }}
                        onChange={(newAttrs) => {
                          setSpots((prevShapes) => {
                            let newShapes = prevShapes.slice();
                            newShapes[i] = newAttrs;
                            setSelected(newAttrs);
                            return newShapes;
                          });
                        }}
                      />
                    ))}

                    {points.map(({ x, y }, index) => (
                      <Circle
                        key={index}
                        x={x}
                        y={y}
                        radius={5}
                        fill="lightblue"
                      />
                    ))}

                    {points.length > 1 && (
                      <Line
                        points={points
                          .map((item) => [item.x, item.y])
                          .flatMap((item) => item)}
                        stroke="black"
                      />
                    )}
                    {mousePosition && points.length > 0 && (
                      <Line
                        points={[mousePosition, points[points.length - 1]]
                          .map((item) => [item.x, item.y])
                          .flatMap((item) => item)}
                        stroke="black"
                      />
                    )}
                  </Layer>
                </Stage>
              </Box>
            </Flex>

            <Flex>
              <Flex direction="column" flex={4 / 12} px={2}>
                <Text fontSize="2xl" marginBottom={2}>
                  Parking Lot Information
                </Text>
                <Text marginBottom={1}>Parking Lot ID</Text>
                <Input
                  value={state.id}
                  placeholder="Enter parking lot id here"
                  onChange={({ target: { value } }) =>
                    setState((prevState) => ({ ...prevState, id: value }))
                  }
                  marginBottom={2}
                />
                <Text marginBottom={1}>Camera ID</Text>
                <Input
                  value={state.cameraId}
                  placeholder="Enter camera id here"
                  onChange={({ target: { value } }) =>
                    setState((prevState) => ({ ...prevState, cameraId: value }))
                  }
                  marginBottom={2}
                />
                <Text fontSize="xl" marginBottom={2}>
                  Parking Lot Timing
                </Text>
                <Text marginBottom={1}>Monday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.mondayStart}
                      type="time"
                      placeholder="monday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          mondayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.mondayEnd}
                      type="time"
                      placeholder="monday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          mondayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Tuesday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.tuesdayStart}
                      type="time"
                      placeholder="tuesday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          tuesdayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.tuesdayEnd}
                      type="time"
                      placeholder="tuesday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          tuesdayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Wednesday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.wednesdayStart}
                      type="time"
                      placeholder="wednesday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          wednesdayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.wednesdayEnd}
                      type="time"
                      placeholder="wednesday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          wednesdayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Thursday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.thursdayStart}
                      type="time"
                      placeholder="thursday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          thursdayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.thursdayEnd}
                      type="time"
                      placeholder="thursday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          thursdayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Friday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.fridayStart}
                      type="time"
                      placeholder="friday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          fridayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.fridayEnd}
                      type="time"
                      placeholder="friday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          fridayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Saturday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.saturdayStart}
                      type="time"
                      placeholder="saturday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          saturdayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.saturdayEnd}
                      type="time"
                      placeholder="saturday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          saturdayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>

                <Text marginBottom={1}>Sunday</Text>
                <Flex direction="row">
                  <Flex direction="column" flex={6 / 12} pr={1}>
                    <Input
                      value={state.sundayStart}
                      type="time"
                      placeholder="sunday opening time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          sundayStart: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                  <Flex direction="column" flex={6 / 12} pl={1}>
                    <Input
                      value={state.sundayEnd}
                      type="time"
                      placeholder="sunday closing time"
                      onChange={({ target: { value } }) =>
                        setState((prevState) => ({
                          ...prevState,
                          sundayEnd: value,
                        }))
                      }
                      marginBottom={2}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" flex={8 / 12} px={2}>
                <Stack direction="row" spacing={2} alignItems="center" pb={2}>
                  <Text fontSize="2xl">Parking Spots</Text>
                  <Tooltip label="Add Spot">
                    <IconButton
                      aria-label="Add Spot"
                      onClick={onAddSpot}
                      boxSize={6}
                      icon={<AddIcon boxSize={3} />}
                    />
                  </Tooltip>
                </Stack>

                {spots.map((item, i) => (
                  <Flex
                    key={i}
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={2}
                    width="100%"
                  >
                    <InputGroup mr={1}>
                      <Input
                        value={item.number}
                        onChange={onChangeSpot(item, "number")}
                        placeholder="number"
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input
                        value={item.time}
                        onChange={onChangeSpot(item, "time")}
                        placeholder="Allowed parking time"
                      />
                      <InputRightAddon>
                        <IconButton
                          variant="ghost"
                          size="sm"
                          colorScheme="red"
                          onClick={onDeleteSpot(item)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputRightAddon>
                    </InputGroup>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Box alignItems="center" justifyContent="center" position="relative">
            <input {...dropZone?.getInputProps()} />
            <FormControl>
              <Box
                sx={{
                  py: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: 1,
                  borderStyle: "dashed",
                  cursor: "pointer",
                }}
                {...dropZone?.getRootProps()}
              >
                <p>
                  {dropZone.isDragActive
                    ? "Drop the file here ..."
                    : "Drag 'n' drop file here, or click to select file"}
                </p>
              </Box>
            </FormControl>
          </Box>
        )}
      </Flex>
    </MainLayout>
  );
}
