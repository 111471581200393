import React from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom/dist";
import { Home, Error } from "views";

createRoot(document.getElementById("root")).render(
  <RouterProvider
    router={createBrowserRouter([
      {
        path: "/",
        element: <Home />,
        errorElement: <Error />,
      },
      {
        path: "*",
        element: <Navigate to={"/"} replace />,
      },
    ])}
  />
);
