import { ChakraProvider, Portal } from "@chakra-ui/react";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Navbar, PanelContainer, PanelContent } from "components";
import theme from "theme/theme.js";

export default function MainLayout({ children }) {
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Portal>
        <Navbar />
      </Portal>
      <PanelContent pt={"75px"}>
        <PanelContainer>{children}</PanelContainer>
      </PanelContent>
    </ChakraProvider>
  );
}
