import { Text } from "@chakra-ui/react";
import { MainLayout } from "layouts";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <MainLayout>
      <Text fontSize="md" color="red">
        There was an error loading the file
      </Text>
      <Link to="/">Go Home</Link>
    </MainLayout>
  );
}
