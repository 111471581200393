import React from "react";
import { Circle, Line } from "react-konva";

const Polygon = ({
  shape,
  isSelected,
  onSelect,
  onChange,
  vectorDimensions: { vectorHeight, vectorWidth },
  ...rest
}) => {
  const handlePointDragMove = (index, event) => {
    let { x, y } = event.target.position();
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    if (x > vectorWidth) x = vectorWidth;
    if (y > vectorHeight) y = vectorHeight;
    // console.log(x, y)
    event.target.position({x, y})

    onChange({
      ...shape,
      points: [
        ...shape.points.slice(0, index),
        { x, y },
        ...shape.points.slice(index + 1),
      ],
    });
  };

  return (
    <React.Fragment>
      <Line
        onClick={onSelect}
        onTap={onSelect}
        id={shape.id}
        // x={0}
        // y={0}
        points={shape?.points?.flatMap((p) => [p.x, p.y])}
        {...rest}
        closed
        // draggable
        // onDragMove={(event) => {
        //   const { x, y } = event.target.position();
        //   console.log(x, y)
        //   event.target.position({ x: 0, y: 0});
        //   onChange({
        //     ...shape,
        //     points: shape.points?.map((point) => ({
        //       x: point.x + x,
        //       y: point.y + y,
        //     })),
        //   });
        // }}
      />
      {shape.points?.map((point, index) => (
        <Circle
          key={index}
          x={point.x}
          y={point.y}
          radius={6}
          fill={isSelected ? "pink" : "lightblue"}
          stroke={isSelected ? "red" : "blue"}
          strokeWidth={2}
          draggable
          onDragMove={(event) => handlePointDragMove(index, event)}
        />
      ))}
    </React.Fragment>
  );
};

export default Polygon;
